<template>
  <v-simple-table class="v-data-table elevation-3 v-data-table--dense mb-5">
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Ausgestllt für</td>
          <td>{{ tableData._subjectName }}</td>
        </tr>
        <tr>
          <td>Gültig bis</td>
          <td>{{ timeConverter(tableData._validTo) }}</td>
        </tr>
        <tr>
          <td>Protokoll</td>
          <td>{{ tableData._protocol }}</td>
        </tr>
        <tr>
          <td>Zertifizierungsstelle</td>
          <td>{{ letsEncrypt(tableData._issuer) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    tableData: Object,
  },
  methods: {
    timeConverter(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp * 1000);
      return a;
    },
    letsEncrypt(issuer) {
        const iss = issuer === 'R3' ? 'let\'s Encrypt' : issuer;
        return iss;
    }
  },
};
</script>