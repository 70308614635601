<template>
  <v-simple-table class="v-data-table elevation-3 v-data-table--dense mb-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Technik</th>
          <th class="text-left">Version</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableData" :key="item.name" >
          <td>{{ item.name }}</td>
          <td>{{ unbekannt(item.version) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    tableData: Array,
  },
  methods: {
      unbekannt(item){
          if(item) return item
          else return 'unbekannt'
      }
  },
};
</script>