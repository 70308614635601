<template>
  <v-card
    class="mx-auto mb-5 mt-3"
    color="#DDD"   
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        mdi-information
      </v-icon>
      <span class="text-h5 font-weight-light">{{infoContent.header}}</span>
    </v-card-title>
    <v-card-text v-html="infoContent.text"></v-card-text>
  </v-card>
</template>
<script>
export default {
    props: {
    infoContent: Object,
  },
};
</script>
