
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { de },
      current: 'de',
    },
  icons: {
    iconfont: 'md',
  },
  theme: {
    options: {
        customProperties: true
    },
    themes: {
        light: {
            primary: "#40719c",
            secondary: "#294777",
            accent: "#ff87E4",
            alert: "FF3333",
            anchor: '#40719c'
        }
    }
}
});
