import { render, staticRenderFns } from "./RatingElement.vue?vue&type=template&id=c0c1fa80&scoped=true&"
import script from "./RatingElement.vue?vue&type=script&lang=js&"
export * from "./RatingElement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0c1fa80",
  null
  
)

export default component.exports