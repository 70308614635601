<template>
  <v-simple-table class="v-data-table elevation-3 v-data-table--dense">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Bibliotheksversion</th>
          <th class="text-left">Höchster Schweregrad</th>
          <th class="text-left">Anzahl der Sicherheitslücken</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableData" :key="item.name"  :class="scoreColor(item.highestSeverity)">
          <td><a :href="item.detectedLib.url" target="_blank" class="black--text">{{ item.detectedLib.text }}</a> </td> 
          <td>
            {{ item.highestSeverity }}
          </td>
          <td>{{ item.vulnCount }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    tableData: Array,
  },
  methods: {
    scoreColor(num) {
      if (num == "High") {
        return "red";
      } else if (num == "Medium") {
        return "orange ";
      } else if (num == "Low") {
        return "yellow darken-2";
      } else return "grey";
    },
  },
};
</script>