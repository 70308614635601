<template>
  <span>
    <v-icon v-if="rating == 'success'" color="green darken-1" >
      mdi-check-circle
    </v-icon>
    <v-icon v-else-if="rating == 'warning'" color="yellow darken-2" >
      mdi-help-circle
    </v-icon>
    <v-icon v-else color="red darken-1" > mdi-alert </v-icon>
  </span>
</template>

<script>
export default {
  name: "IsDanger",
  props: {
    rating: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
</style>