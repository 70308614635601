import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"2","outlined":"","color":"primary"}},[_c(VCardTitle,{staticClass:"white--text"},[_c('img',{staticClass:"wpIcon",attrs:{"src":_vm.wIcon,"width":"40","height":"40"}}),_vm._v(" Wordpress Sanierung")]),_c(VCardText,{staticClass:"bgwhite pt-4"},[_c('h4',[_vm._v("Unser Angebot")]),_c('p',{staticClass:"text-justify"},[_vm._v(" Der Webseiten-Check hat erhebliche Defiziete Ihrer Webseite in allen drei Bereichen ergeben. Wir können Ihnen mit einer Wordpress-Sanierung helfen. Die Sanierung enthält: ")]),_c('ul',[_c('li',[_vm._v("Experten-Konfiguration und ggf Austausch Ihres Cookie-Banners gegen ein besseres Produkt.")]),_c('li',[_vm._v("DSGVO-konforme Integration Ihrer benötigten 3rd-Party-Tools")]),_c('li',[_vm._v("Austausch von Webfont gegen lokale Schriftarten")]),_c('li',[_vm._v("Datenschutz-Report nach Abschluss unserer Arbeiten.")]),_c('li',[_vm._v("Sicherheits-Paket: Austausch von Javascript-Bibliotheken mit Sicherheitslücken gegen gepatchte Version.")]),_c('li',[_vm._v("Sicherheits-Paket: Implemetierung der relevanten Sicherheits-Header in Ihrem Apache- oder Nginx-Webserver.")]),_c('li',[_vm._v("Sicherheits-Paket: Implemetierung der bestmöglichen Content Security Policy.")]),_c('li',[_vm._v("Wordpress Boost: Installation Und Konfiguration eines Optimierungs-Plugins.")]),_c('li',[_vm._v("Wordpress Boost: Optimierung der .htaccess-Datei.")])])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"white","href":"https://wp.devcon2.de/produkt/wordpress-sanierung/"}},[_vm._v(" Jetzt buchen für 999€ ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }