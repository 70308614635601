import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"2","outlined":"","color":"primary"}},[_c(VCardTitle,{staticClass:"white--text"},[_c('img',{staticClass:"wpIcon",attrs:{"src":_vm.wIcon,"width":"40","height":"40"}}),_vm._v(" Wordpress Datenschutz")]),_c(VCardText,{staticClass:"bgwhite pt-4"},[_c('h4',[_vm._v("Unser Angebot")]),_c('p',{staticClass:"text-justify"},[_vm._v(" Der Webseiten-Check hat erhebliche Defizite Ihrer Webseite im Bereich Datenschutz. Wir können Ihnen helfen. Die Sanierung enthält: ")]),_c('ul',[_c('li',[_vm._v("Experten-Konfiguration und ggf Austausch Ihres Cookie-Banners gegen ein besseres Produkt.")]),_c('li',[_vm._v("DSGVO-konforme Integration Ihrer benötigten 3rd-Party-Tools")]),_c('li',[_vm._v("Austausch von Webfont gegen lokale Schriftarten")]),_c('li',[_vm._v("Datenschutz-Zertifikat nach Abschluss unserer Arbeiten.")])])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"white","href":"https://wp.devcon2.de/produkt/datenschutz-optimierung-ihrer-wordpress-webseite/"}},[_vm._v(" Jetzt buchen für 699€ ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }