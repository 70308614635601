<template>
  <v-card elevation="2" outlined color="primary">
    <v-card-title class="white--text"><img class="wpIcon" :src="wIcon" width="40" height="40" /> Wordpress Boost</v-card-title>
    <v-card-text class="bgwhite pt-4">
      <h4>Unser Angebot</h4>
      <p class="text-justify">
        Performance Boost durch Optimierung Ihrer Wordpress Installation.
      </p>
      <ul>
        <li>
          Installation Und Konfiguration eines Optimierungs-Plugins.
        </li>
        <li>
          Optimierung der .htaccess-Datei
        </li>
      </ul>

    </v-card-text>
    <v-card-actions>
      <v-btn color="white"  href="https://wp.devcon2.de/produkt/wordpress-boost/">
        Jetzt buchen für 269€
      </v-btn> 
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    wIcon: String,
  },
  methods: {
      unbekannt(item){
          if(item) return item
          else return 'unbekannt'
      }
  },
};
</script>
<style scoped>
.bgwhite{
    background-color: #fff;
}
.wpIcon{
    filter: brightness(0) invert(1);
    margin-right: 10px;
}
</style>

