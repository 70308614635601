<template>
  <v-footer padless color="primary">
    <v-col class="text-center white--text" cols="12">
      Webseiten-Report Version {{ appVersion }}  © {{ new Date().getFullYear() }} — {{ company }}
      
    </v-col>
  </v-footer>
</template>

<script>
import { version } from '../../package.json'
export default {
  data() {
    return {
      company: "<DEVCON2/> UG",
      appVersion: version
    };
  },
};
</script>