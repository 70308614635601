<template>
    <v-app>
      <ReportComponent /> 
      <FooterComponent /> 
    </v-app>    
</template>

<script>

import ReportComponent from './components/ReportComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
export default {
  name: 'App',
  components: {
    ReportComponent,
    FooterComponent
  }
}
</script>

<style lang="scss">
#app {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
