<template>
  <v-simple-table class="v-data-table elevation-3 v-data-table--dense mb-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left">Wert</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item, key in tableData" :key="key" >
          <td>{{ key }}</td>
          <td>
            {{ item }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    tableData: Object,
  },
  methods: {
  },
};
</script>