<template>
  <v-simple-table class="v-data-table elevation-3 v-data-table--dense mb-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Vorhanden</th>
          <th class="text-left">Header</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item, key in tableData.passed" :key="key" >
          <td><v-icon  color="green darken-1" large> mdi-check-circle </v-icon></td>
          <td>{{ item }}</td>
        </tr>
        <tr v-for="item, key in tableData.failed" :key="key+50" > 
          <td><v-icon  color="red darken-1" large> mdi-close-octagon </v-icon></td>
          <td>{{ item }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    tableData: Object,
  },
  methods: {
  },
};
</script>