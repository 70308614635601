import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"2","outlined":"","color":"primary"}},[_c(VCardTitle,{staticClass:"white--text"},[_c('svg',{staticClass:"wpIcon",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5V11C15.4,11 16,11.6 16,12.3V15.8C16,16.4 15.4,17 14.7,17H9.2C8.6,17 8,16.4 8,15.7V12.2C8,11.6 8.6,11 9.2,11V9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5V11H13.5V9.5C13.5,8.7 12.8,8.2 12,8.2Z"}})]),_vm._v(" Sicherheits-Paket")]),_c(VCardText,{staticClass:"bgwhite pt-4"},[_c('h4',[_vm._v("Unser Angebot")]),_c('p',{staticClass:"text-justify"},[_vm._v(" Wir können Ihnen helfen, Ihren Webspace relativ schnell um einiges sicherer zu machen. ")]),_c('ul',[_c('li',[_vm._v(" Austausch von Javascript-Bibliotheken mit Sicherheitslücken gegen gepatchte Version. ")]),_c('li',[_vm._v(" Implemetierung der relevanten Sicherheits-Header in Ihrem Apache- oder Nginx-Webserver. ")]),_c('li',[_vm._v(" Implemetierung der bestmöglichen Content Security Policy ")])])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"white","href":"https://wp.devcon2.de/produkt/sicherheits-paket/"}},[_vm._v(" Jetzt buchen für 249€ ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }