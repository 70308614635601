<template>
  <v-card elevation="2" outlined :color="scoreColor()" class="mt-8">
    <v-card-title class="white--text">
      <v-icon color="white" class="ico-lg wpIcon">
        mdi-information
      </v-icon>
      {{ oRating }} ({{ oScore }} von 300 Punkten)
    </v-card-title>
    <v-card-text class="bgwhite pt-4">
      <h3>Was besagt das Ergebnis?</h3>
      <p>Zugegeben, dieser Report ist schon sehr umfangreich und ziemlich nerdy, daher hier erst einmal in aller Kürze die
        Bewertung dieser Seite und unserer Bewertungsmatrix</p>

      <v-row no-gutters>
        <v-col cols="12" md="4">
          <h4>Datenschutz</h4>
          <v-simple-table class="v-data-table v-data-table--dense mb-2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left minw">Score</th>
                  <th class="text-left">Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><v-icon color="green darken-1" large> mdi-thumb-up </v-icon><br>100/100</td>
                  <td>KEINE Datenschutz-Verstöße festgestellt</td>
                </tr>
                <tr>
                  <td><v-icon color="yellow darken-2" large> mdi-help-circle-outline </v-icon><br>50/100</td>
                  <td>MÖGLICHE Datenschutz-Verstöße festgestellt</td>
                </tr>
                <tr>
                  <td><v-icon color="red darken-1" large> mdi-alert </v-icon><br>0/100</td>
                  <td>Datenschutz-Verstöße festgestellt!</td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="4">
          <h4>Sicherheit</h4>
          <v-simple-table class="v-data-table v-data-table--dense mb-2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left minw">Score</th>
                  <th class="text-left">Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><v-icon color="green darken-1" large> mdi-checkbox-blank-circle-outline </v-icon><br>> 70</td>
                  <td>HOHE Sicherheit. Gute Arbeit</td>
                </tr>
                <tr>
                  <td><v-icon color="yellow darken-2" large> mdi-checkbox-blank-circle-outline </v-icon><br>40-70</td>
                  <td>MITTLERE Sicherheit. Verbesserung empfohlen</td>
                </tr>
                <tr>
                  <td><v-icon color="red darken-1" large> mdi-checkbox-blank-circle-outline </v-icon><br>0-39</td>
                  <td>GERINGE Sicherheit. Verbesserung notwendig</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="4">
          <h4>Leistung</h4>
          <v-simple-table class="v-data-table v-data-table--dense mb-2 ">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left minw">Score</th>
                  <th class="text-left">Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><v-icon color="green darken-1" large> mdi-checkbox-blank-circle-outline </v-icon><br>> 70</td>
                  <td>SCHNELLE Webseite</td>
                </tr>
                <tr>
                  <td><v-icon color="yellow darken-2" large> mdi-checkbox-blank-circle-outline </v-icon><br>40-70</td>
                  <td>AKZEPTABLE Geschwindigkeit</td>
                </tr>
                <tr>
                  <td><v-icon color="red darken-1" large> mdi-checkbox-blank-circle-outline </v-icon><br>0-39</td>
                  <td>LANGSAME Webseite</td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>


    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    wIcon: String,
    oScore: Number,
  },
  data() {
    return {
      color: "primary",
      oRating: "Perfekt",
      toolsInfo: {},
    }
  },
  mounted() {

  },
  methods: {
    scoreColor() {
      if (this.oScore > 210) {
        this.color = "success";
        this.oRating = "Gut";
      }
      else if (this.oScore > 120) {
        this.color = "warning"
        this.oRating = "Akzeptabel";
      }
      else {
        this.color = "alert"
        this.oRating = "Ungenügend";
      }
      return this.color
    }
  },
};
</script>
<style scoped>
.bgwhite {
  background-color: #fff;
}
.minw {
  min-width: 55px;
}

.wpIcon {
  filter: brightness(0) invert(1);
  margin-right: 10px;
  font-size: 32px !important;
}

.v-card__title {

  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: .0125em;
  line-height: 2.5rem;

}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px 0 0;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
</style>

