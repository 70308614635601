<template>
  <v-card elevation="2" outlined color="primary">
    <v-card-title class="white--text"><img class="wpIcon" :src="wIcon" width="40" height="40" /> Wordpress Datenschutz</v-card-title>
    <v-card-text class="bgwhite pt-4">
      <h4>Unser Angebot</h4>
      <p class="text-justify">
        Der Webseiten-Check hat erhebliche Defizite Ihrer Webseite im Bereich Datenschutz. Wir können Ihnen helfen.
        Die Sanierung enthält:
      </p>
      <ul>        
        <li>Experten-Konfiguration und ggf Austausch Ihres Cookie-Banners gegen ein besseres Produkt.</li>
        <li>DSGVO-konforme Integration Ihrer benötigten 3rd-Party-Tools</li>
        <li>Austausch von Webfont gegen lokale Schriftarten</li>
        <li>Datenschutz-Zertifikat nach Abschluss unserer Arbeiten.</li>
      </ul>
    </v-card-text>
    <v-card-actions>
      <v-btn color="white" href="https://wp.devcon2.de/produkt/datenschutz-optimierung-ihrer-wordpress-webseite/">
        Jetzt buchen für 699€
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    wIcon: String,
  }
};
</script>
<style scoped>
.bgwhite{
    background-color: #fff;
}
.wpIcon{
    filter: brightness(0) invert(1);
    margin-right: 10px;
}
</style>

