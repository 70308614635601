<template>
  <span v-if="!isNaN(score)">
    <v-icon v-if="score > 80" color="green darken-1" x-large>
      mdi-thumb-up
    </v-icon>
    <v-icon v-else-if="score > 40" color="yellow darken-2" x-large>
      mdi-emoticon-neutral
    </v-icon>
    <v-icon v-else color="red darken-1" x-large> mdi-thumb-down </v-icon>
  </span>
  <span v-else-if="rating">
    <v-icon v-if="rating == 'FAST'" color="green darken-1" x-large>
      mdi-thumb-up
    </v-icon>
    <v-icon v-else-if="rating == 'AVERAGE'" color="yellow darken-2" x-large>
      mdi-emoticon-neutral
    </v-icon>
    <v-icon v-else color="red darken-1" x-large> mdi-thumb-down </v-icon>
  </span>
</template>

<script>
export default {
  name: "MyRating",
  props: {
    score: {
      type: Number,
      required: false,
    },
    rating: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
</style>